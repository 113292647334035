.feedback
  color: $c-anthrazit
  background-image: url('../../assets/images/feedback/clouds.svg')
  background-size: contain
  background-repeat: no-repeat
  height: 100%

  &__submitted
    pointer-events: none

  &_padding
    padding: 4.125rem

  &__header
    gap: 4.969rem
    &__logo
      width: 10.18rem

  &__bottom
    &_button
      margin-top: 5rem
      border-radius: 0.6rem
      font-size: 1.875rem
      padding: 0.5rem 2rem

    &__rating-container
      margin-top: 10rem

  &__counter
    &_img
      width: 15.688rem

    &__name
      color: $c-anthrazit

    &_button
      gap: 1rem

  &__stars
    margin-bottom: 2.063rem

    &__stars-container
      margin: 2.063rem 0
      gap: 3.344rem

      &__star
        width: 3.688rem

  &__reasons
    &__header
      margin-bottom: 4.563rem

    &__reasons-container
      gap: 3rem

    &__reason
      gap: 1rem

      &__icon
        width: 2.188rem

      &__info
        cursor: pointer

  &__thanking-container
    left: 0
    top: 0
    background-color: rgba(0, 0, 0, 0.6)

    &__heading
      font-size: 4.688rem

  &__back
    &_icon-container
      left: 0

    &_icon
      width: 3.5rem

  &__progress
    margin-top: 6.688rem
    margin-bottom: 6.688rem
    gap: 1rem

    &__bar
      width: 51.25rem

  &__dish-ranking
    gap: 3rem

  &__dish
    gap: 5.875rem

    &_image
      height: 100%
      width: 100%
      object-fit: cover

      &__container
        width: 8.875rem
        height: 8.875rem
        overflow: hidden
        position: relative

    &__rating
      gap: 1rem

  &_h1
    font-size: 4.375rem // TODO: Unused, remove it maybe

  &_h2
    font-size: 3.438rem !important

  &_h3
    font-size: 2.5rem !important

  &_h4
    font-size: 2.188rem !important

  &_h5
    font-size: 1.875rem !important

  &_h6
    font-size: 1.563rem !important
